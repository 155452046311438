.signin-button {
  margin-top: 16px !important;
}
.link-clear {
  background-color: transparent !important;
  display: inline !important;
}

.link-clear::before {
  background-color: transparent !important;
  display: inline !important;
}
