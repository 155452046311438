.grow {
  flex: 1 1 auto,
}

.root {
  display: 'flex';
  align-items: 'stretch';
  min-height: 100vh;
  width: 100%;
}

.drawer {
  width: 250px;
  border-right: none !important;
}

a {
  color: inherit;
}

.drawerItem {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.12);
}